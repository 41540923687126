<script setup lang="ts">
import { organizationData } from '@/utils/static-structured-data'

const { slug } = useRoute().params

const { story } = await useFetchStory(
  slug && slug.length > 0 && typeof slug !== 'string' ? slug.join('/') : 'home',
).catch((error) => {
  throw createError({
    message: error,
    statusCode: 404,
  })
})

definePageMeta({
  key: (route) => route.fullPath,
})
useCustomHead(story, {
  jsonLd: typeof slug === 'string' ? organizationData : undefined,
})

const { layoutName, layoutNavbarColor, layoutButtonText } =
  useLayout<any>(story)
</script>

<template>
  <NuxtLayout
    :name="layoutName"
    :navbar-color="layoutNavbarColor"
    :layoutButtonText="layoutButtonText"
  >
    <StoryblokComponent
      v-for="hero in story.content.hero"
      :key="hero._uid"
      :blok="hero"
    />
    <DelayHydration>
      <StoryblokComponent
        v-for="body in story.content.body"
        :key="body._uid"
        :blok="body"
      />
    </DelayHydration>
  </NuxtLayout>
</template>
